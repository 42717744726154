import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "nateShoemaker",
  "categories": ["elixir"],
  "date": "2020-04-17",
  "path": "/blog/elixir-demystifying-quote",
  "summary": "Demystifying the quote macro.",
  "title": "Macro See, Macro Do",
  "image": "./macro-see-macro-do-ogp.png",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This post assumes you have Erlang/Elixir installed and that you can spin up a Phoenix project`}</em></p>
    <p>{`I've been diving into Elixir and Phoenix as of late. I have thoroughly enjoyed my time spent with both and could gush about them for far, far too long. Elixir has some features whose underlying implementation wasn't obvious at first glance, namely the macro `}<inlineCode parentName="p">{`quote/2`}</inlineCode>{` (that slash and number indicates the arity).`}</p>
    <p>{`I first came across `}<inlineCode parentName="p">{`quote/2`}</inlineCode>{` in a fresh Phoenix project. Let's create one now! Run `}<inlineCode parentName="p">{`mix phx.new foo`}</inlineCode>{`, open up `}<inlineCode parentName="p">{`lib/foo_web/foo_web.ex`}</inlineCode>{` and see `}<inlineCode parentName="p">{`quote/2`}</inlineCode>{` being used:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# lib/foo_web/foo_web.ex`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defmodule`}</span>{` FooWeb `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` controller `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    quote `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`use`}</span>{` Phoenix`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Controller`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token attr-name"
          }}>{`namespace:`}</span>{` FooWeb

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` Plug`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Conn
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` FooWeb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Gettext
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`alias`}</span>{` FooWeb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Router`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Helpers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token attr-name"
          }}>{`as:`}</span>{` Routes
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ...`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p><inlineCode parentName="p">{`controller/0`}</inlineCode>{` is then used in controllers like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# lib/foo_web/controllers/page_controller.ex`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defmodule`}</span>{` FooWeb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PageController `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`use`}</span>{` FooWeb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:controller`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ...`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Going through `}<a parentName="p" {...{
        "href": "https://pragprog.com/book/phoenix14/programming-phoenix-1-4"
      }}>{`Programming Phoenix`}</a>{` I saw this macro being used again and again. I understood what it `}<em parentName="p">{`meant`}</em>{`: the `}<inlineCode parentName="p">{`use`}</inlineCode>{`, `}<inlineCode parentName="p">{`import`}</inlineCode>{`, and `}<inlineCode parentName="p">{`alias`}</inlineCode>{` macros are being injected into `}<inlineCode parentName="p">{`PageController`}</inlineCode>{`, so dependencies can be shared across modules. But why not just include them in the function definition? What is going behind the scenes? Why `}<inlineCode parentName="p">{`quote/2`}</inlineCode>{`? Being a Rails developer accustomed to magic, I accepted it and moved on.`}</p>
    <p>{`One of Phoenix's (and Elixir) strengths is that nothing is hidden from the developer. Everything is gloriously defined, displayed, and explicitly composed right in front of you. There really `}<em parentName="p">{`isn't`}</em>{` any magic. Thus my acceptance of it bothered me, so let's dive in and learn about `}<inlineCode parentName="p">{`quote`}</inlineCode>{` together!`}</p>
    <h2>{`Copy and (almost) paste`}</h2>
    <p>{`The best way to learn is by doing, so why don't we create some modules and reproduce what we've seen. Here's a very simple example I came up with:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# bar.exs`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defmodule`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Math `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` sum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token attr-name"
          }}>{`do:`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` y
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defmodule`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`AllTheThings `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` things `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    quote `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`alias`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Math
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defmodule`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Work `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`use`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`AllTheThings`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:things`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` print_sum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    IO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`puts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"the sum of `}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span>{`x`}<span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span>{` and `}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span>{`y`}<span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span>{` is `}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span>{`sum`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`x`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` y`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span>{`"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Work`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`print_sum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Again, I don't `}<em parentName="p">{`really`}</em>{` know what `}<inlineCode parentName="p">{`quote/2`}</inlineCode>{` is doing and why, but we mimicked what we saw in Phoenix pretty close. I think we're ready to try this out, let's run `}<inlineCode parentName="p">{`elixir bar.exs`}</inlineCode>{` and see what happens:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "cli"
    }}><pre parentName="div" {...{
        "className": "language-cli"
      }}><code parentName="pre" {...{
          "className": "language-cli"
        }}>{`> elixir bar.exs
** (UndefinedFunctionError) function Bar.AllTheThings.__using__/1 is undefined or private
    Bar.AllTheThings.__using__(:things)
    bar.exs:18: (module)
    bar.exs:17: (file)`}</code></pre></div>
    <p>{`:thinking:`}</p>
    <p>{`It seems that we're missing a function that Elixir assumes we have implemented. I'll be honest - I've never written a module that was consumed by `}<inlineCode parentName="p">{`use`}</inlineCode>{`, so let's double back to `}<inlineCode parentName="p">{`FooWeb`}</inlineCode>{` in our Phoenix App to see if we missed anything. At the bottom of the file, you'll see:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}>{`  defmacro __using__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` is_atom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    apply`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`__MODULE__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Ah! Elixir was looking for that function, so let's slap that it in `}<inlineCode parentName="p">{`Bar.AllTheThings`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`defmodule`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`AllTheThings `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` things `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    quote `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`alias`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Math
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  defmacro __using__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` is_atom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    apply`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`__MODULE__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Diving into `}<inlineCode parentName="p">{`defmacro`}</inlineCode>{` is outside the scope of this post, but we can acknowledge it as a requirement of a module that's consumed by `}<inlineCode parentName="p">{`use`}</inlineCode>{`. The use of `}<inlineCode parentName="p">{`apply/3`}</inlineCode>{` is straightforward: take a module, an atom that represents the function name, and call it with some arguments.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}>{`apply`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`AllTheThings`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:things`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# is equivalent to`}</span>{`
Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`AllTheThings`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`things`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`And then:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "cli"
    }}><pre parentName="div" {...{
        "className": "language-cli"
      }}><code parentName="pre" {...{
          "className": "language-cli"
        }}>{`> elixir bar.exs
the sum of 2 and 2 is 4`}</code></pre></div>
    <p>{`Great, our dependency injection works. Now that we understand the structure let's dig into what's happening under the hood.`}</p>
    <h2>{`I heard you like Elixir, so let's represent some Elixir with Elixir`}</h2>
    <p>{`From the `}<a parentName="p" {...{
        "href": "https://hexdocs.pm/elixir/Kernel.SpecialForms.html#quote/2"
      }}>{`docs`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`> quote(opts, block)

Gets the representation of any expression.`}</code></pre></div>
    <p>{`Let's try it out:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`> iex
iex(1)> quote do sum(2, 2) end
{:sum, [], [2, 2]}`}</code></pre></div>
    <p>{`That's right! We are representing Elixir with Elixir. Elixir's AST (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Abstract_syntax_tree"
      }}>{`abstract syntax tree`}</a>{`) is... Elixir! Pretty cool, huh? Macros, such as `}<inlineCode parentName="p">{`quote/2`}</inlineCode>{`, are represented by a tuple of three elements. The first element is (usually) an atom, the second is for metadata, and the third is the argument list.`}</p>
    <p>{`I wonder what our `}<inlineCode parentName="p">{`import Bar.Math`}</inlineCode>{` looks like as an AST? Let's find out! Comment out everything in `}<inlineCode parentName="p">{`bar.exs`}</inlineCode>{` except for the `}<inlineCode parentName="p">{`Bar.Math`}</inlineCode>{` module. Rename the file to `}<inlineCode parentName="p">{`bar.ex`}</inlineCode>{` so Elixir can compile it, and run `}<inlineCode parentName="p">{`iex`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "cli"
    }}><pre parentName="div" {...{
        "className": "language-cli"
      }}><code parentName="pre" {...{
          "className": "language-cli"
        }}>{`> iex
iex(1)> c "bar.ex"
[Bar.Math]
iex(2)> quote do
...(2)>   import Bar.Math
...(2)> end
{:import, [context: Elixir], [{:__aliases__, [alias: false], [:Bar, :Math]}]}`}</code></pre></div>
    <p>{`There it is! We can see the AST as a three element tuple. It holds all the information that Elixir needs to know to `}<inlineCode parentName="p">{`import`}</inlineCode>{` a module. `}<inlineCode parentName="p">{`quote/2`}</inlineCode>{` gives us some fantastic syntax sugar; could you imagine writing these tuples everywhere? Just for fun, let's see how deep into the rabbit hole we can go. Rename `}<inlineCode parentName="p">{`bar.ex`}</inlineCode>{` back to `}<inlineCode parentName="p">{`bar.exs`}</inlineCode>{`, uncomment all the code, and change the `}<inlineCode parentName="p">{`import Bar.Math`}</inlineCode>{` to the AST representation without `}<inlineCode parentName="p">{`quote/2`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# bar.exs`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ...`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defmodule`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`AllTheThings `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` things `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:import`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token attr-name"
          }}>{`context:`}</span>{` Elixir`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:__aliases__`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token attr-name"
          }}>{`alias:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:Bar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:Math`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  defmacro __using__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` is_atom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    apply`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`__MODULE__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ...`}</span></code></pre></div>
    <p>{`And:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "cli"
    }}><pre parentName="div" {...{
        "className": "language-cli"
      }}><code parentName="pre" {...{
          "className": "language-cli"
        }}>{`> elixir bar.exs
the sum of 2 and 2 is 4`}</code></pre></div>
    <p>{`It works! Let's go another level in by removing `}<inlineCode parentName="p">{`things/0`}</inlineCode>{` and placing our AST directly in `}<inlineCode parentName="p">{`__using/1`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# bar.exs`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ...`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defmodule`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`AllTheThings `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  defmacro __using__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` is_atom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`which`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:import`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token attr-name"
          }}>{`context:`}</span>{` Elixir`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:__aliases__`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token attr-name"
          }}>{`alias:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:Bar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:Math`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ...`}</span></code></pre></div>
    <p>{`You know the drill:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "cli"
    }}><pre parentName="div" {...{
        "className": "language-cli"
      }}><code parentName="pre" {...{
          "className": "language-cli"
        }}>{`> elixir bar.exs
the sum of 2 and 2 is 4`}</code></pre></div>
    <p>{`Nice! Is it possible to inline the AST we have `}<em parentName="p">{`in`}</em>{` our `}<inlineCode parentName="p">{`Bar.Work`}</inlineCode>{` module? Sadly, we can't. The `}<inlineCode parentName="p">{`use/2`}</inlineCode>{` macro changes this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`use`}</span>{` Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`AllTheThings`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:things`}</span></code></pre></div>
    <p>{`to:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "elixir"
    }}><pre parentName="div" {...{
        "className": "language-elixir"
      }}><code parentName="pre" {...{
          "className": "language-elixir"
        }}>{`Bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`AllTheThings`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`__using__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token atom symbol"
          }}>{`:things`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`We've come to the end of this Elixir in Elixir train! There are no other stops on this line.`}</p>
    <h3>{`Wrapping up`}</h3>
    <p>{`So, what did we learn? The `}<inlineCode parentName="p">{`quote`}</inlineCode>{` macro transforms Elixir code to an AST. We can then leverage that to achieve real dependency injection in a functional language. How cool is that?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      